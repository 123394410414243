/* latin-ext */
@font-face {
  font-family: '__Poppins_9c579b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/30cd8f99d32fa6e8-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_9c579b';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_9c579b';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_9c579b {font-family: '__Poppins_9c579b', '__Poppins_Fallback_9c579b';font-weight: 700;font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Sora_9db8ab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/11d35c0cc1e61808-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Sora_9db8ab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/eec24fd1a6a58119-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Sora_Fallback_9db8ab';src: local("Arial");ascent-override: 85.29%;descent-override: 25.50%;line-gap-override: 0.00%;size-adjust: 113.73%
}.__className_9db8ab {font-family: '__Sora_9db8ab', '__Sora_Fallback_9db8ab';font-weight: 700;font-style: normal
}

